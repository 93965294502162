<template>
  <div>
    <div
      class="container-fluid d-flex flex-column justify-content-between subscribe_layout align-items-center"
    >
      <div
        style="
          text-align: end;
          color: #d1d0cf;
          align-items: end !important;
          margin: 10px;
          width: 100%;
        "
      >
        <div></div>
        <div v-if="$i18n.locale === 'en'" @click="changeLang('ar')">عربي</div>
        <div v-if="$i18n.locale === 'ar'" @click="changeLang('en')">
          English
        </div>
      </div>
      <div class="text-center">
        <div style="font-size: 12px">{{ $t('login.header') }}</div>
      </div>
      <img src="@/assets/images/logo.png" class="test" alt="" />
      <div class="text-center align-items-center">
        <router-view :key="$route.path"></router-view>
      </div>
      <div>
        <div
          style="text-anchor: middle; font-size: 0.6rem; color: #fff"
          :class="$i18n.locale === 'ar' ? 'dir-rtl' : 'dir-ltr '"
        >
          {{ $t('login.subTerms') }}
        </div>

        <ul
          v-if="$i18n.locale === 'ar'"
          style="
            font-size: 0.6rem;
            color: #fff;
            margin: 10px;
            direction: rtl;
            text-align: start;
          "
        >
          <li>أهلاً بكِ في بوابة HighFit. تقدم هذه الخدمة للمستخدم</li>
          <li>
            مجانًا لمدة 24 ساعة بعد ذلك، سيتم تلقائيًا تحصيل 2.10 درهما يوميًا.
          </li>
          <li>
            لا يوجد التزام، يمكنك إلغاء الاشتراك في أي وقت عن طريق إرسال C HIF
            إلى 1111
          </li>
          <li>للمساعدة، يرجى التواصل مع support@mt2morrow.com</li>
          <li>التجربة المجانية متاحة لمشتركي اتصالات الجدد فقط</li>
          <li>استمتع بتجربتك المجانية لمدة 24 ساعة</li>
          <li>
            يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية
            وأن لديك اتصال إنترنت آمن للوصول السريع إلى المحتوى
          </li>
          <li>
            تقنيات الحظر ولديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.
          </li>
          <li>
            لكي تستطيع استخدام هذه الخدمة يجب عليك أن تكون فوق الـ18 عامًا أو
            يكون لديك إذن من عائلتك أو من الشخص المفوض بدفع فاتورة هاتفك.
            الأسعار تشمل ضريبة القيمة المضافة بنسبة 5%
          </li>
          <li>
            للحصول على كامل الشروط والأحكام
            <span
              style="text-decoration: underline; color: #e03339"
              v-b-modal.my-modal
              >{{ $t('login.clickHere') }}</span
            >
          </li>
        </ul>
        <ul
          v-else
          style="
            font-size: 0.6rem;
            color: #fff;
            margin: 10px;
            text-align: start;
          "
        >
          <li>Welcome to the HighFit portal. This service offers the user</li>
          <li>
            Free for 24 hours then, you will be charged AED 2.10/day
            automatically.
          </li>
          <li>
            No commitment, you can cancel any time by sending C HIF to 1111.
          </li>
          <li>For support, please contact us at support@mt2morow.com</li>
          <li>Free trial applicable only for first time subscriber.</li>
          <li>Enjoy your Free trial for 24 hours.</li>
          <li>
            Please make sure that your browser is not using any 3rd-party.
          </li>
          <li>
            Blocking technologies and you have a healthy internet connection for
            swift access to the content.
          </li>
          <li>
            To make use of this service, you must be 18 or more unless you have
            received permission from your parents or the person who is
            authorized to pay your bill. Prices are subject to 5% VAT
          </li>
          <li>
            For full terms and condition
            <span
              style="text-decoration: underline; color: #e03339"
              v-b-modal.my-modal
              >{{ $t('login.clickHere') }}</span
            >
          </li>
        </ul>
      </div>
      <b-modal
        id="my-modal"
        :title="$t('login.terms')"
        hide-footer
        header-class="text-end"
      >
        <div style="font-size: 14px" v-if="$i18n.locale === 'en'">
          <strong>1. Free Trial Period</strong>
          <br />
          Upon subscribing to the service, you will receive a 24-hour free trial
          period. During this time, you can access all the features and benefits
          of the service without any charge.
          <br />
          <strong>2. Subscription Fee</strong>
          <br />

          After the 24-hour free trial period ends, a daily fee of AED 2.10 (VAT
          included) will be automatically charged to your account. This fee will
          be deducted every day to maintain your subscription and access to the
          service.
          <br />
          <strong>3. Automatic Renewal</strong>
          <br />

          The subscription service is designed to renew automatically after the
          initial free trial period. This means that after the first 24 hours,
          the daily charge of AED 2.10 will continue to be applied without any
          further action required from your side.
          <br />
          <strong>4. No Commitment</strong>
          <br />

          The service offers flexibility with no long-term commitment required.
          You have the freedom to cancel the subscription at any time without
          any penalties or additional charges.
          <br />
          <strong>5. Cancellation Process</strong>
          <br />

          If you wish to cancel your subscription, you can do so easily. To
          cancel, simply send a text message with the code "C HIF" to the number
          1111. Upon sending this message, your subscription will be terminated,
          and you will no longer be charged the daily fee.
        </div>
        <div style="font-size: 14px; direction: rtl; text-align: start" v-else>
          <strong>1. فترة التجربة المجانية</strong>
          <br />
          عند الاشتراك في الخدمة، ستحصل على فترة تجربة مجانية لمدة 24 ساعة.
          وخلال هذه الفترة، يمكنك الوصول إلى جميع ميزات وفوائد الخدمة دون أي
          رسوم.

          <br />
          <strong>2. تكلفة الاشتراك</strong>
          <br />

          بعد انتهاء فترة التجربة المجانية لمدة 24 ساعة، سيتم خصم تكلفة يومية
          بقيمة 2.10 درهم إماراتي (بما في ذلك ضريبة القيمة المضافة) تلقائيًا على
          حسابك. سيتم خصم هذه الرسوم يوميًا للحفاظ على اشتراكك والوصول إلى
          الخدمة.

          <br />
          <strong>3. التجديد التلقائي</strong>
          <br />

          تم تصميم خدمة الاشتراك للتجديد تلقائيًا بعد فترة التجربة المجانية
          الأولية. وهذا يعني أنه بعد أول 24 ساعة، ستستمر التكلفة اليومية بقيمة
          2.10 درهم إماراتي في التطبيق دون الحاجة إلى أي إجراء آخر من جانبك.

          <br />
          <strong>4. عدم الالتزام</strong>
          <br />

          تقدم الخدمة المرونة دون الحاجة إلى التزام طويل الأجل. لديك الحرية في
          إلغاء الاشتراك في أي وقت دون أي عقوبات أو تكلفة إضافية.

          <br />
          <strong>5. عملية الإلغاء</strong>
          <br />

          إذا كنت ترغب في إلغاء اشتراكك، يمكنك القيام بذلك بسهولة. لإلغاء
          الاشتراك، ما عليك سوى إرسال رسالة نصية بالرمز "C HIF" إلى الرقم 1111.
          عند إرسال هذه الرسالة، سيتم إنهاء اشتراكك ولن يتم تحصيل الرسوم اليومية
          منك بعد الآن.
        </div>
      </b-modal>
    </div>
  </div>
</template>

<style lang="scss">
.subscribe_layout {
  background-image: url('../assets/images/subscribe/subscribe_desk_bg.png');
  min-height: 100vh;
  background-position: center;
  background-size: 100% 100%;

  @media (max-width: 768px) {
    background-image: url('../assets/images/subscribe/bg_mobile.png');
  }
}

.dir-ltr {
  direction: ltr;
  text-align: left;
}

.dir-rtl {
  direction: rtl;
  text-align: right;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 500px;
    font-size: 40.621px;
    line-height: 49px;
  }

  @media (max-width: 600px) {
    width: 100%;
    font-size: 22.621px;
    line-height: 22px;
  }

  .text_info {
    color: white;

    @media (min-width: 600px) {
      font-size: 38px;
    }

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  .input,
  .butn {
    width: inherit;
  }

  .game_input,
  .game_btn {
    width: 100%;
    border: 0.2px solid #ffffff;
    background: linear-gradient(90deg, #0e2229 0%, #0e2229 100%);
    opacity: 0.7;
    border-radius: 40px;
    font-style: normal;
    font-weight: 400;
    color: white;

    @media (min-width: 600px) {
      height: 81px;
    }

    @media (max-width: 600px) {
      height: 55px;
    }
  }
  .game_btn {
    width: 50%;
    font-size: 16px;
  }
  .activeBtn {
    background: #defe71 !important;
    color: black;
  }

  .game_input {
    @media (min-width: 600px) {
      padding-left: 60px;
    }

    @media (max-width: 600px) {
      padding-left: 20px;
    }
  }

  .game_btn {
    outline: none;
    padding: 0px;
  }

  #otp {
    display: flex;
    justify-content: space-around;
    width: inherit;
    margin-bottom: 2rem;

    input {
      height: 70px;
      width: 70px;
      text-align: center;
      background: linear-gradient(90deg, #161621 0%, #0e2229 100%);
      opacity: 0.7;
      font-size: 20px;
      outline: none;
      border-radius: 8px;
      border: 0.2px solid #ffffff;
      color: white;

      @media (max-width: 600px) {
        height: 60px;
        width: 20%;
      }
    }
  }

  .desc {
    width: 80%;
    text-align: center;
    line-height: 1.6;

    @media (min-width: 600px) {
      font-size: 16px;
    }

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
}

::placeholder {
  color: white;
}
</style>
<script>
import ApiService from '../services/api'

export default {
  name: 'SubscribeLayout',
  methods: {
    changeLang (lang) {
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
      ApiService.changeLAng()
      this.$http.defaults.headers.common['lang'] = localStorage.getItem('lang')
    }
    // goToTermsAndConditions () {
    //   console.log('test')
    //   this.$router.push('/terms-and-conditions')
    // }
  }
}
</script>
